import React from "react"
import { Seo } from "src/component/common/seo"
import Industries from "src/component/layout/industries"
import { StaticImage } from "gatsby-plugin-image"
import ManufacturingCasestudyList from "src/component/list/manufacturingCasestudyList"

export default function Manufacturing() {
    return (
      <>
        <Industries>
         <div className="manufacturing inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_manufacturing.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/industries/industries_manufacturing-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>製造業</p>
                                  <span>MANUFACTURING</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  生産計画、サプライチェーン最適化、調達リスクなどの課題を解決。
                                  <br />
                                    DATAFLUCTの高い技術力で、従来にないデータ分析を実現し、柔軟かつ迅速に
                                    <br />
                                    市場変化へ対応できる体制構築を支援します。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>AIが拓く、<br />競争力ある生産の未来</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>製造データ基盤の構築</h3>
                                 <p>
                                     工場の生産データを統合し、リアルタイムで稼働状況を可視化。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>設備メンテナンスの予測保全</h3>
                                 <p>
                                   センサーデータを解析し、機械の故障リスクを事前に検知。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIによる品質管理の強化</h3>
                                 <p>
                                    画像認識AIを活用し、不良品の自動検出と品質管理を高度化。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>スマートファクトリーの実現</h3>
                                 <p>
                                   IoTとAIを組み合わせ、全工程の自動化と生産効率の最大化を推進。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>生産計画の最適化</h3>
                                 <p>
                                    需要予測を基に、最適な生産スケジュールを立案し、生産効率を向上。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>     
                        </div>
                        <ManufacturingCasestudyList/>
                    </div>
             </section>
            </div>
          </Industries>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="MANUFACTURING"
            description=""
            pathname=""
        />
    )
}